import React, {useCallback, useEffect, useState} from 'react';
import './wheel.scss';
import {useTranslation} from "react-i18next";
import 'react-before-after-slider-component/dist/build.css';


const bonusLink = 'https://oshcasino.com';
function Wheel() {
    const lang = localStorage.getItem('selectedLanguage')

    const queryParams = new URLSearchParams(window.location.search);

    const bypassParams = [
        'affid',
        'subid',
        'subid1',
        'subid2',
        'subid3',
        'subid4',
        'subid5',
        'subid6',
        'subid7',
        'subid8',
        'subid9',
        'subid10',
        'cid'
    ];

    const generateLink = useCallback(() => {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());

        const bag: any = {};
        for (const index in bypassParams) {
            const name = bypassParams[index];
            const value = queryParams.get(name)
                || cookies.find(cookie => cookie.startsWith(name + '='))?.split('=').slice(1).join('=');

            if (value) {
                bag[name] = value;
            }
        }

        const queryString = Object.keys(bag)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(bag[key]))
            .join('&');

        return `${bonusLink}/${lang}?registration&${queryString}`;
    }, []);

    useEffect(() => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        const expirationDateString = expirationDate.toUTCString();

        for (const index in bypassParams) {
            const name = bypassParams[index];
            const value = queryParams.get(name);
            if (value) {
                document.cookie = `${name}=${value}; expires=${expirationDateString}; path=/`;
            }

        }
    }, []);
    const { t } = useTranslation();
    const bonuses = [
        {
            id: 1,
            text: t('web.wheel:550'),
        },
        {
            id: 2,
            text: t('web.wheel:zero'),
        },
        {
            id: 3,
            text:t('web.wheel:bonus'),
        },
        {
            id: 4,
            text: t('web.wheel:200'),
        },
        {
            id: 5,
            text:t('web.wheel:2222'),
        },
        {
            id: 6,
            text:t('web.wheel:425'),
        },
        {
            id: 7,
            text:t('web.wheel:222 FS'),
        },
        {
            id: 8,
            text:t('web.wheel:250'),
        },
        {
            id: 9,
            text:t('web.wheel:2000'),
        },
        {
            id: 10,
            text:t('web.wheel:25'),
        },
        {
            id: 11,
            text:t('web.wheel:100'),
        },
        {
            id: 12,
            text:t('web.wheel:150'),
        },
    ]

    const [isSpinning, setIsSpinning] = useState(false);
    const [rotation, setRotation] = useState(15);
    const [spinCount, setSpinCount] = useState(2);
    const [winningSection, setWinningSection] = useState<number | null>(null);
    const [firstWin, setFirstWin] = useState<number | null>(null);
    const [secondWin, setSecondWin] = useState<number | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    const spinWheel = (targetSection: number) => {
        if (isSpinning) return;

        setIsSpinning(true);
        setSpinCount(spinCount - 1);

        const sections = bonuses.length;
        const sectionAngle = 360 / sections;
        const randomOffset = Math.floor(Math.random() * 20) + 5;
        const fullRotations = 360 * randomOffset;

        // Определяем угол для остановки на нужной секции
        const targetAngle = sectionAngle * targetSection;

        const totalRotation = targetAngle + fullRotations;

        setRotation(-totalRotation + 15);

        setTimeout(() => {
            setIsSpinning(false);
            setWinningSection(targetSection);
            if (firstWin) {
                setSecondWin(targetSection)
            }
            if (!firstWin) {
                setFirstWin(targetSection)
            }
            setTimeout(() => {
                setWinningSection(null);
            }, 2000);
        }, 4000); // Время должно совпадать с временем анимации
    };

    useEffect(() => {
        if (secondWin) {
            setTimeout(() => {
                setModalOpen(true);
            }, 2000);
        }
    }, [secondWin]);

    const spinWheelButtonAction = () => {
        if (spinCount === 1) {
            spinWheel(6)
        }
        if (spinCount === 2) {
            spinWheel(4)
        }
    }

    const currency = () => {
        switch (lang) {
            case 'en-ca': return 'С$'
            case 'fr-ca': return 'С$'
            case 'pt-br': return 'R$'
            default: return '€'
        }
    }

    const redirect = () => {
        if (spinCount === 0) {
            window.location.href = generateLink();
        }
        else {
            spinWheelButtonAction()
        }
    }


  return (
      <div className="app" onClick={() => redirect()}>
          <img className="app-decoration" src='/images/main-decoration.webp' alt="decoration"/>
          <main className="main">
              <div className={`
              main__girl1
              ${(firstWin || secondWin) ? 'main__girl1--haveWin' : ''}
              `}>
                  <img
                      src='/images/girl-2.webp'
                      alt="decoration"
                  />
                  <div className="win__container">
                      {!!firstWin && (
                          <div className="win__item">
                              {bonuses[firstWin].text}
                              {!!Number(bonuses[firstWin].text) && (
                                  <div>{currency()}</div>
                              )}
                          </div>
                      )}
                      {!!secondWin && (
                          <div className="win__item">
                              {bonuses[secondWin].text}
                              {!!Number(bonuses[secondWin].text) && (
                                  <div>{currency()}</div>
                              )}
                          </div>
                      )}
                  </div>
              </div>
              <div className="wheel-container">
                      <div className="wheel__text">
                          <div className="wheel__text--bold">{t('web.wheel:LUCKY SPIN')}</div>
                          <div className="wheel__text--normal">{t('web.wheel:WIN UP TO')}
                              <div className="wheel__text--accent">{t('web.wheel:2000')}
                                  {!!Number(t('web.wheel:2000')) && (
                                      <div>{currency()}</div>
                                  )}
                              </div>
                              {t('web.wheel:BONUS TO YOUR DEPOSIT')}</div>
                      </div>
                    <div>
                      <div className="wheel">
                          <div className="wheel__bg">
                              <img
                                  src='/wheel/wheel-bg.webp'
                                  alt="decoration"
                              />
                              <div className='wheel__bg--spins'>
                                  <div className='wheel__bg--spins-text'>
                                      {t('web.wheel:Spins')}
                                  </div>
                                  <div className='wheel__bg--spins-count'>
                                      {spinCount}
                                  </div>
                              </div>
                          </div>
                          <div className={`
                        wheel__wheel
                        ${!winningSection ? 'noAnimation' : ''}
                        `}>
                              <div
                                  className={
                                      `wheel__wheel--text
                            `}
                                  style={{
                                      transform: `rotate(${rotation}deg)`,
                                      transition: isSpinning ? 'transform 4s ease-out' : 'none'
                                  }}
                              >
                                  {bonuses.map((item, index) => (
                                      <div
                                          key={item.id}
                                          className={`
                                        wheel__wheel--field-container
                                        ${item.id === 2 ? 'zero' : ''}
                                        ${item.id === 3 ? 'bonus' : ''}
                                        ${item.id === 9 ? 'max' : ''}
                                        ${winningSection === index ? 'highlight' : ''}
                                        `}
                                      >
                                          <div className="wheel__wheel--field"
                                               style={{transform: `rotate(${30 * index}deg) skewX(-60deg)`}}
                                          />
                                          <div className="wheel__wheel--field-text-wrapper"
                                               style={{transform: `rotate(${30 * index - 15}deg)`}}
                                          >
                                              <div className="wheel__wheel--field-text">
                                                  {item.text}
                                                  {!!Number(item.text) && (
                                                      <div>{currency()}</div>
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  ))}
                              </div>
                          </div>
                          <img
                              className="wheel__arrow"
                              src='/wheel/arrow.webp'
                              alt="decoration"
                          />
                      </div>
                      <div className="wheel__button-container">
                          <button
                              className="wheel__button"
                              disabled={isSpinning || spinCount === 0}
                          >
                              {t('web.wheel:SPIN AND WIN')}
                          </button>
                      </div>
                    </div>
              </div>
              <img
                  className="main__girl2"
                  src='/images/girl-1.webp'
                  alt="decoration"
              />
          </main>
          <div className={`
          win-modal
          ${modalOpen ? 'win-modal-open' : ''}
          `}>
              {modalOpen && (
                  <div className="app-coins-fall">
                      <img className="app-coin1" src='/coins/c01.webp' alt="coin1"/>
                      <img className="app-coin2" src='/coins/c02.webp' alt="coin1"/>
                      <img className="app-coin2-2" src='/coins/c02.webp' alt="coin1"/>
                      <img className="app-coin3" src='/coins/c03.webp' alt="coin1"/>
                      <img className="app-coin3-2" src='/coins/c03.webp' alt="coin1"/>
                      <img className="app-coin4" src='/coins/c04.webp' alt="coin1"/>
                      <img className="app-coin4-2" src='/coins/c04.webp' alt="coin1"/>
                      <img className="app-coin5" src='/coins/c05.webp' alt="coin1"/>
                      <img className="app-coin5-2" src='/coins/c05.webp' alt="coin1"/>
                      <img className="app-coin6" src='/coins/c06.webp' alt="coin1"/>
                      <img className="app-coin6-2" src='/coins/c06.webp' alt="coin1"/>
                  </div>
              )}
              <div className={`
              app-coins
              ${modalOpen ? 'fall' : ''}
              `}>
                  <img className="app-coin1" src='/coins/c01.webp' alt="coin1"/>
                  <img className="app-coin2" src='/coins/c02.webp' alt="coin1"/>
                  <img className="app-coin2-2" src='/coins/c02.webp' alt="coin1"/>
                  <img className="app-coin3" src='/coins/c03.webp' alt="coin1"/>
                  <img className="app-coin3-2" src='/coins/c03.webp' alt="coin1"/>
                  <img className="app-coin4" src='/coins/c04.webp' alt="coin1"/>
                  <img className="app-coin4-2" src='/coins/c04.webp' alt="coin1"/>
                  <img className="app-coin5" src='/coins/c05.webp' alt="coin1"/>
                  <img className="app-coin5-2" src='/coins/c05.webp' alt="coin1"/>
                  <img className="app-coin6" src='/coins/c06.webp' alt="coin1"/>
                  <img className="app-coin6-2" src='/coins/c06.webp' alt="coin1"/>
              </div>
              {modalOpen && (
                  <div className="win-modal-text">
                      <div>
                          <div className="win-modal-text__text">
                              {t('web.wheel:you won')}
                              <img
                                  className="win-modal-text__congrats"
                                  src='/images/congrats.webp'
                                  alt="decoration"
                              />
                          </div>
                          {firstWin && secondWin && (
                              <div className="win__item">
                                  {bonuses[firstWin].text}
                                  {!!Number(bonuses[firstWin].text) && (
                                      <div>{currency()}</div>
                                  )}
                                  +
                                  {bonuses[secondWin].text}
                                  {!!Number(bonuses[secondWin].text) && (
                                      <div>{currency()}</div>
                                  )}
                              </div>
                          )}
                      </div>
                      <button
                          className="wheel__button"
                      >
                          {t('web.wheel:get your reward')}
                      </button>
                  </div>
              )}
          </div>
      </div>
  );
}

export default Wheel;
